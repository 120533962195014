import { useDispatch } from "react-redux";
import { GenerateOTPForLogin } from "./LoginSlice";
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { faAlignJustify } from "@fortawesome/free-solid-svg-icons";
//import { useNavigate } from "react-router-dom";



function Login() {




  const dispatch = useDispatch();

  const history = useHistory();


  // const { openAddCountryDialog, setOpenAddCountryDialog } = prop;
  // const [icon,setIcon] = useState('')
  const validationSchema = Yup.object().shape({

    email: Yup.string().required('Email is required').email('Enter a valid email address'),
    mobilenumber: Yup.string().required('Phone  is required').max(10).required('Mobile Number should be maximum of 10 characters'),

    // loginType: Yup.boolean().required().oneOf([0 , 1], 'Selecting the field is required'),


  });


  const defaultValues = {

    mobilenumber: '',
    email: '',
    loginType: 'email',
  };



  const { handleSubmit, register, reset, setValue, control, watch, formState } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const { isValid, dirtyFields, errors } = formState;

  const data = watch();
  console.log(data);







  const onSubmit = () => {

    if (data.loginType == 'mobile') {
      //  setValue('email','')
      data.email = ''
      console.log(data)
      const object = JSON.stringify(data);
      console.log(object)

       
    }
    else {
      setValue('mobilenumber', '')
      console.log(data)
      const object = JSON.stringify(data);
      console.log(object)
      var res=dispatch(GenerateOTPForLogin(object))
       history.push('/verify')
      //dispatch(GenerateOTPForLogin(object))
    }

   // history.push('/verify')

    //console.log(data)

    // const object = JSON.stringify(obj);
    // console.log(object)
    // dispatch(GenerateOTPForLogin(object))
  }

  useEffect(() => {
    if (data.loginType == 'mobile') {
      console.log("number")
      // unregister("email")
      // setValue('mobilenumber','')
      setValue('email', 'example@g.com')

    } if (data.loginType == 'email') {
      console.log("email")
      //setValue('email','')
      setValue('mobilenumber', ' ',)
      // unregister("mobilenumber")
    }
  }, [data.loginType, setValue])



  return (
    <div>

      <div id="pageloader-overlay" className="visible incoming"><div className="loader-wrapper-outer"><div className="loader-wrapper-inner"><div className="loader" /></div></div></div>

      <div id="wrapper">
        <div className="loader-wrapper"><div className="lds-ring"><div /><div /><div /><div /></div></div>
        <div className="card card-authentication1 mx-auto my-5">
          <div className="card-body">
            <div className="card-content p-2">
              <div className="text-center">
                <img src="assets/images/logo-icon.png" alt="logo icon" />
              </div>
              <div className="card-title text-uppercase text-center py-3">Sign In</div>
              <form className="w-full" onSubmit={handleSubmit(onSubmit)}>


                <div className="form-group">
                  <div className="text-center">
                    <div className="form-check-inline ">
                      <div >
                        <Controller
                          name="loginType"
                          //type="radio"
                          control={control}
                          render={({ field: { onChange, value } }) => (

                            <div style={{ display: 'flex' }}>
                              <div className="icheck-material-white" >
                                <input type="radio" value='email' id="registerTypeEmail" {...register("loginType")} defaultChecked defaultValue="email" />

                                <label htmlFor="registerTypeEmail">Email</label>


                              </div>

                              &nbsp;&nbsp;&nbsp;
                              <div className="icheck-material-white">





                                <input type="radio" value='mobile' {...register("loginType")} id="registerTypePhone" defaultValue="phone" />


                                <label htmlFor="registerTypePhone">Phone</label>
                              </div>

                            </div>
                          )}
                        /></div>

                    </div>
                  </div>
                </div>

                <div className="form-group">
                  {/* <label for="exampleInputUsername" class="sr-only">Email</label> */}


                  {data.loginType == 'email' ?


                    <div className="position-relative has-icon-right" id="emailInput">


                      <Controller
                        name="email"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <input type="email"   {...register("email")} id="exampleInputUsername" name="email" className="form-control input-shadow" placeholder="Enter Email" />
                        )}
                      />
                      <div style={{ marginTop: '10px' }}>
                        {errors.email && <span style={{ color: 'red' }}>{errors?.email?.message}</span>}
                      </div>



                      <div className="form-control-position">
                        <i className="icon-user" />
                      </div>
                    </div> : <div className="position-relative has-icon-right" id="phoneInput">

                      <Controller
                        name="mobilenumber"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <input type="number" maxLength={10}  {...register("mobilenumber")} name='mobilenumber' id="exampleInputPhone" className="form-control input-shadow" placeholder="Enter phone" />

                        )}
                      />
                      <div style={{ marginTop: '10px' }}>
                        {errors.mobilenumber && <span style={{ color: 'red' }}>{errors?.mobilenumber?.message}</span>}
                      </div>


                      <div className="form-control-position">
                        <i className="icon-phone" />
                      </div>
                    </div>
                  }


                  <div className="position-relative has-icon-right mt-2" style={{ display: 'none' }} id="listInput">
                    <select id="exampleInputUsername" className="form-control input-shadow" placeholder="Please Select">
                      <option selected>Select</option>
                      <option>Intravet</option>
                      <option>Avimark</option>
                      <option>Cornerstone</option>
                    </select>
                    {/* <input type="text" id="exampleInputUsername" class="form-control input-shadow" placeholder="Enter Phone" > */}
                    {/* <div class="form-control-position">
									<i class="icon-phone"></i>
								</div> */}
                  </div>
                </div>
                <button type="submit" className="btn btn-light btn-block">
                  Send OTP</button>
                {/* <button type="button" className="btn btn-light btn-block" onClick={(e)=>handleSubmit(e)}>            */}
              </form>
            </div>
          </div>
          {/* <div class="card-footer text-center py-3">
				<p class="text-warning mb-0">Do not have an account? <a href="register.html"> Sign Up here</a></p>
			</div> */}
        </div>

        <a href="javaScript:void();" className="back-to-top"><i className="fa fa-angle-double-up" /> </a>

        <div className="right-sidebar">
          <div className="switcher-icon">
            <i className="zmdi zmdi-settings zmdi-hc-spin" />
          </div>
          <div className="right-sidebar-content">
            <p className="mb-0">Gaussion Texture</p>
            <hr />
            <ul className="switcher">
              <li id="theme1" />
              <li id="theme2" />
              <li id="theme3" />
              <li id="theme4" />
              <li id="theme5" />
              <li id="theme6" />
            </ul>
            <p className="mb-0">Gradient Background</p>
            <hr />
            <ul className="switcher">
              <li id="theme7" />
              <li id="theme8" />
              <li id="theme9" />
              <li id="theme10" />
              <li id="theme11" />
              <li id="theme12" />
              <li id="theme13" />
              <li id="theme14" />
              <li id="theme15" />
            </ul>
          </div>
        </div>

      </div>


    </div>

  );
}

export default Login;