import axios from 'axios';

const BASE_URL = "http://54.215.1.73/";

export const publicRequest = axios.create({
    baseURL:BASE_URL,
    headers: {
        'accept': '*/*',
        'Content-Type': 'application/json',
    },
})