const { configureStore } = require("@reduxjs/toolkit");
import footerSlice from '../sections/Footer/Store/FooterSlice'
import loginSlice from '../templates/Login/LoginSlice'
import VerifyOtpSlice from '../templates/verifyotp/VerifyOtpSlice';

const store = configureStore({
    reducer:{
        footer:footerSlice,
        login:loginSlice,
        verifyotp : VerifyOtpSlice
     }
 
});

export default store;
