//import logo from './logo.svg';

import Sidebar from "../sections/Sidebar";
import TopNav from "../sections/TopNav";


function Profile() {
  return (
    <div>
      {/* start loader */}
      <div id="pageloader-overlay" className="visible incoming"><div className="loader-wrapper-outer"><div className="loader-wrapper-inner"><div className="loader" /></div></div></div>
      {/* end loader */}
      {/* Start wrapper*/}
      
      <div id="wrapper">

      <Sidebar/>
      <TopNav/>
        {/*Start sidebar-wrapper*/}
       
        {/*End sidebar-wrapper*/}

        {/*Start topbar header*/}
       
        {/*End topbar header*/}
        <div className="clearfix" />
        
        <div className="content-wrapper">
        <div className="container-fluid">
          <div className="row mt-3">
            <div className="col-lg-4">
              <div className="card profile-card-2">
                <div className="card-img-block">
                  <img className="img-fluid" src="https://via.placeholder.com/800x500" alt="Card image cap" />
                </div>
                <div className="card-body pt-5">
                  <img src="assets/images/profile.png" alt="profile-image" className="profile" />
                  <h5 className="card-title">Mark Stern</h5>
                  <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                  {/* <div class="icon-block">
                  <a href="javascript:void();"><i class="fa fa-facebook bg-facebook text-white"></i></a>
				  <a href="javascript:void();"> <i class="fa fa-twitter bg-twitter text-white"></i></a>
				  <a href="javascript:void();"> <i class="fa fa-google-plus bg-google-plus text-white"></i></a>
                </div> */}
                </div>
                {/*  <div class="card-body border-top border-light">
                 <div class="media align-items-center">
                   <div>
                       <img src="assets/images/timeline/html5.svg" class="skill-img" alt="skill img">
                   </div>
                     <div class="media-body text-left ml-3">
                       <div class="progress-wrapper">
                         <p>HTML5 <span class="float-right">65%</span></p>
                         <div class="progress" style="height: 5px;">
                          <div class="progress-bar" style="width:65%"></div>
                         </div>
                        </div>                   
                    </div>
                  </div>
                  <hr>
                  <div class="media align-items-center">
                   <div><img src="assets/images/timeline/bootstrap-4.svg" class="skill-img" alt="skill img"></div>
                     <div class="media-body text-left ml-3">
                       <div class="progress-wrapper">
                         <p>Bootstrap 4 <span class="float-right">50%</span></p>
                         <div class="progress" style="height: 5px;">
                          <div class="progress-bar" style="width:50%"></div>
                         </div>
                        </div>                   
                    </div>
                  </div>
                   <hr>
                  <div class="media align-items-center">
                   <div><img src="assets/images/timeline/angular-icon.svg" class="skill-img" alt="skill img"></div>
                     <div class="media-body text-left ml-3">
                       <div class="progress-wrapper">
                         <p>AngularJS <span class="float-right">70%</span></p>
                         <div class="progress" style="height: 5px;">
                          <div class="progress-bar" style="width:70%"></div>
                         </div>
                        </div>                   
                    </div>
                  </div>
                    <hr>
                  <div class="media align-items-center">
                   <div><img src="assets/images/timeline/react.svg" class="skill-img" alt="skill img"></div>
                     <div class="media-body text-left ml-3">
                       <div class="progress-wrapper">
                         <p>React JS <span class="float-right">35%</span></p>
                         <div class="progress" style="height: 5px;">
                          <div class="progress-bar" style="width:35%"></div>
                         </div>
                        </div>                   
                    </div>
                  </div>
                  
              </div>*/}
              </div> 
            </div>
            <div className="col-lg-8">
              <div className="card">
                <div className="card-body">
                  <ul className="nav nav-tabs nav-tabs-primary top-icon nav-justified">
                    {/* <li class="nav-item">
                    <a href="javascript:void();" data-target="#profile" data-toggle="pill" class="nav-link active"><i class="icon-user"></i> <span class="hidden-xs">Profile</span></a>
                </li>
                <li class="nav-item">
                    <a href="javascript:void();" data-target="#messages" data-toggle="pill" class="nav-link"><i class="icon-envelope-open"></i> <span class="hidden-xs">Messages</span></a>
                </li> */}
                    <li className="nav-item">
                      <a href="javascript:void();" data-target="#edit" data-toggle="pill" className="nav-link active"><i className="icon-note" /> <span className="hidden-xs">Edit</span></a>
                    </li>
                  </ul>
                  <div className="tab-content p-3">
                    <div className="tab-pane " id="profile">
                      <h5 className="mb-3">User Profile</h5>
                      <div className="row">
                        <div className="col-md-6">
                          <h6>About</h6>
                          <p>
                            Web Designer, UI/UX Engineer
                          </p>
                          <h6>Hobbies</h6>
                          <p>
                            Indie music, skiing and hiking. I love the great outdoors.
                          </p>
                        </div>
                        <div className="col-md-6">
                          <h6>Recent badges</h6>
                          <a href="javascript:void();" className="badge badge-dark badge-pill">html5</a>
                          <a href="javascript:void();" className="badge badge-dark badge-pill">react</a>
                          <a href="javascript:void();" className="badge badge-dark badge-pill">codeply</a>
                          <a href="javascript:void();" className="badge badge-dark badge-pill">angularjs</a>
                          <a href="javascript:void();" className="badge badge-dark badge-pill">css3</a>
                          <a href="javascript:void();" className="badge badge-dark badge-pill">jquery</a>
                          <a href="javascript:void();" className="badge badge-dark badge-pill">bootstrap</a>
                          <a href="javascript:void();" className="badge badge-dark badge-pill">responsive-design</a>
                          <hr />
                          <span className="badge badge-primary"><i className="fa fa-user" /> 900 Followers</span>
                          <span className="badge badge-success"><i className="fa fa-cog" /> 43 Forks</span>
                          <span className="badge badge-danger"><i className="fa fa-eye" /> 245 Views</span>
                        </div>
                        <div className="col-md-12">
                          <h5 className="mt-2 mb-3"><span className="fa fa-clock-o ion-clock float-right" /> Recent Activity</h5>
                          <div className="table-responsive">
                            <table className="table table-hover table-striped">
                              <tbody>                                    
                                <tr>
                                  <td>
                                    <strong>Abby</strong> joined ACME Project Team in <strong>`Collaboration`</strong>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Gary</strong> deleted My Board1 in <strong>`Discussions`</strong>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Kensington</strong> deleted MyBoard3 in <strong>`Discussions`</strong>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>John</strong> deleted My Board1 in <strong>`Discussions`</strong>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Skell</strong> deleted his post Look at Why this is.. in <strong>`Discussions`</strong>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      {/*/row*/}
                    </div>
                    <div className="tab-pane" id="messages">
                      <div className="alert alert-info alert-dismissible" role="alert">
                        <button type="button" className="close" data-dismiss="alert">×</button>
                        <div className="alert-icon">
                          <i className="icon-info" />
                        </div>
                        <div className="alert-message">
                          <span><strong>Info!</strong> Lorem Ipsum is simply dummy text.</span>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table table-hover table-striped">
                          <tbody>                                    
                            <tr>
                              <td>
                                <span className="float-right font-weight-bold">3 hrs ago</span> Here is your a link to the latest summary report from the..
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span className="float-right font-weight-bold">Yesterday</span> There has been a request on your account since that was..
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span className="float-right font-weight-bold">9/10</span> Porttitor vitae ultrices quis, dapibus id dolor. Morbi venenatis lacinia rhoncus. 
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span className="float-right font-weight-bold">9/4</span> Vestibulum tincidunt ullamcorper eros eget luctus. 
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span className="float-right font-weight-bold">9/4</span> Maxamillion ais the fix for tibulum tincidunt ullamcorper eros. 
                              </td>
                            </tr>
                          </tbody> 
                        </table>
                      </div>
                    </div>
                    <div className="tab-pane active" id="edit">
                      <form>
                        <div className="form-group row">
                          <label className="col-lg-3 col-form-label form-control-label">First name</label>
                          <div className="col-lg-9">
                            <input className="form-control" type="text" defaultValue="Mark" />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-lg-3 col-form-label form-control-label">Last name</label>
                          <div className="col-lg-9">
                            <input className="form-control" type="text" defaultValue="Jhonsan" />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-lg-3 col-form-label form-control-label">Email</label>
                          <div className="col-lg-9">
                            <input className="form-control" type="email" defaultValue="mark@example.com" />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-lg-3 col-form-label form-control-label">Change profile</label>
                          <div className="col-lg-9">
                            <input className="form-control" type="file" />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-lg-3 col-form-label form-control-label">Website</label>
                          <div className="col-lg-9">
                            <input className="form-control" type="url" defaultValue />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-lg-3 col-form-label form-control-label">Address</label>
                          <div className="col-lg-9">
                            <input className="form-control" type="text" defaultValue placeholder="Street" />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-lg-3 col-form-label form-control-label" />
                          <div className="col-lg-6">
                            <input className="form-control" type="text" defaultValue placeholder="City" />
                          </div>
                          <div className="col-lg-3">
                            <input className="form-control" type="text" defaultValue placeholder="State" />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-lg-3 col-form-label form-control-label">Username</label>
                          <div className="col-lg-9">
                            <input className="form-control" type="text" defaultValue="jhonsanmark" />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-lg-3 col-form-label form-control-label">Password</label>
                          <div className="col-lg-9">
                            <input className="form-control" type="password" defaultValue={11111122333} />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-lg-3 col-form-label form-control-label">Confirm password</label>
                          <div className="col-lg-9">
                            <input className="form-control" type="password" defaultValue={11111122333} />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-lg-3 col-form-label form-control-label" />
                          <div className="col-lg-9">
                            <input type="reset" className="btn btn-light mr-1" defaultValue="Cancel" />
                            <input type="button" className="btn btn-light" defaultValue="Save Changes" />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*start overlay*/}
          <div className="overlay toggle-menu" />
          {/*end overlay*/}
        </div>
        {/* End container-fluid*/}
      </div>{/*End content-wrapper*/}



        {/*Start Back To Top Button*/}
        <a href="javaScript:void();" className="back-to-top"><i className="fa fa-angle-double-up" /> </a>
        {/*End Back To Top Button*/}
        {/*Start footer*/}
        <footer className="footer">
          <div className="container">
            <div className="text-center">
              Copyright © 2022 Voice Vet
            </div>
          </div>
        </footer>
        {/*End footer*/}
        {/*start color switcher*/}
        <div className="right-sidebar">
          <div className="switcher-icon">
            <i className="zmdi zmdi-settings zmdi-hc-spin" />
          </div>
          <div className="right-sidebar-content">
            <p className="mb-0">Gaussion Texture</p>
            <hr />
            <ul className="switcher">
              <li id="theme1" />
              <li id="theme2" />
              <li id="theme3" />
              <li id="theme4" />
              <li id="theme5" />
              <li id="theme6" />
            </ul>
            <p className="mb-0">Gradient Background</p>
            <hr />
            <ul className="switcher">
              <li id="theme7" />
              <li id="theme8" />
              <li id="theme9" />
              <li id="theme10" />
              <li id="theme11" />
              <li id="theme12" />
              <li id="theme13" />
              <li id="theme14" />
              <li id="theme15" />
            </ul>
          </div>
        </div>
        {/*end color switcher*/}
      </div>{/*End wrapper*/}
    </div>
  );
}
export default Profile;
