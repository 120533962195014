import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
//import store from "./redux/store"
import App from "./app/App"
// import "./styles/main.scss"
import reportWebVitals from './reportWebVitals';
import './index.css';

import './styles/css/pace.min.css';
import './styles/css/bootstrap.min.css';
import './styles/css/animate.css';
import './styles/css/icons.css';
import './styles/css/sidebar-menu.css';
import './styles/css/app-style.css';
import store from "./reduxtoolkit/store";

ReactDOM.render(
	
	<Provider store={store}>
		<App />
	</Provider>,
	
	document.getElementById("root")
)
reportWebVitals();