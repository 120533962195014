//import logo from './logo.svg';
import '../styles/css/VoiceRecord.css';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import React ,{ useEffect, useState } from 'react';
import Sidebar from '../sections/Sidebar';
import TopNav from '../sections/TopNav';
import useSpeechToText from 'react-hook-speech-to-text';
import { faL } from '@fortawesome/free-solid-svg-icons';


var finaleSpeech=''

function VoiceRecord() {

  const [message, setMessage] = useState('')
  const commands = [
    {
      command: 'I would like to order *',
      callback: (food) => setMessage(`Your order is for: ${food}`)
    },
    {
      command: 'The weather is :condition today',
      callback: (condition) => setMessage(`Today, the weather is ${condition}`)
    },
    {
      command: 'My top sports are * and *',
      callback: (sport1, sport2) => setMessage(`#1: ${sport1}, #2: ${sport2}`)
    },
    {
      command: 'Pass the salt (please)',
      callback: () => setMessage('My pleasure')
    },
    {
      command: ['Hello', 'Hi'],
      callback: ({ command }) => setMessage(`Hi there! You said: "${command}"`),
      matchInterim: true
    },
    {
      command: 'Beijing',
      callback: (command, spokenPhrase, similarityRatio) => setMessage(`${command} and ${spokenPhrase} are ${similarityRatio * 100}% similar`),
      // If the spokenPhrase is "Benji", the message would be "Beijing and Benji are 40% similar"
      isFuzzyMatch: true,
      fuzzyMatchingThreshold: 0.2
    },
    {
      command: ['eat', 'sleep', 'leave'],
      callback: (command) => setMessage(`Best matching command: ${command}`),
      isFuzzyMatch: true,
      fuzzyMatchingThreshold: 0.2,
      bestMatchOnly: true
    },
    {
      command: 'clear',
      callback: ({ resetTranscript }) => resetTranscript()
    }
  ]
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();


  var {
    error,
    interimResult,
    isRecording,
    setIsRecording,
    results,
    setResults,
    startSpeechToText,
    stopSpeechToText
  } = useSpeechToText({
    continuous: true,
    crossBrowser: true,
    googleApiKey: 'AIzaSyCMN65zp4Sno_gzAoR1NkiOXAyZ455P430',
    speechRecognitionProperties: { interimResults: true },
    useLegacyResults: false
   
  });


  
 
// console.log(results);

//console.log()

if(results.length > 0){
   finaleSpeech = results.map((s)=>s.transcript).join(' ');
}

//console.log(finaleSpeech)

const [speech , setSpeech] = useState('')

const [newline , setNewline] =  useState(false)


//console.log(speech.join(' '))

// if(speech != ''){
  
// }


useEffect(()=>{
      if(finaleSpeech != undefined){
        setSpeech(finaleSpeech)
      }
},[results])

// const handleSpeech = (e) => {
//   console.log(e.target.value)
//   setSpeech(speech + e.target.value)
// }

//console.log(speech)




  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  
  return (
    <>
      {/* start loader */}
      <div id="pageloader-overlay" className="visible incoming"><div className="loader-wrapper-outer"><div className="loader-wrapper-inner"><div className="loader" /></div></div></div>
      {/* end loader */}
      {/* Start wrapper*/}
      <div id="wrapper">
       
     <Sidebar/>
     <TopNav/>
     
        <div className="clearfix" />


        <div className="content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card mt-3">
                  <div className="card-body d-flex" style={{padding: '0 1.25rem 0 0'}}>
                    <img src="assets/images/profile1.png" style={{borderRadius: '0 50% 50% 0', width: '110px'}} />
                    <div className="p-3" style={{width: '100%'}}> 
                      <h5 className=" mb-0">Patient Name: Cat {/* <span class="float-right"><i class="fa fa-user"></i> </span>*/}</h5>
                      <div className="progress my-3" style={{height: '3px'}}>
                        {/* <div class="progress-bar" style="width:55%"></div> */}
                      </div>
                      <p className="mb-0  small-font">Patient Owner: Mark {/* <span class="float-right">+4.2% <i class="zmdi zmdi-long-arrow-up"></i></span> */}</p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title mb-1">Record Dictation</h5>
                    <hr className="my-2" />
                    <div className="row">
                      <div className="col-lg-9">
                        <div className="editBtnSection">
                          <button className="btn btn-light mr-1" title="View" data-toggle="modal" data-target="#exampleModalCenter"><i className="fa fa-eye" /> </button>
                          <button className="btn btn-light" title="Edit"><i className="fa fa-pencil" /> </button>
                        </div>
                        <div id="result" className="p-0">
                          <div className="input-single">
                          <textarea id="note-textarea" style={{}} placeholder="Create a new note by typing or using voice recognition." rows={20} defaultValue={""}
                                  value={speech} 
                                  //  onChange={(e)=>handleSpeech(e)}
                                  />

                                 
                            {/* <textarea id="note-textarea" style={{}} placeholder="Create a new note by typing or using voice recognition." rows={20} defaultValue={""}
                                  value={finaleSpeech} /> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                         <p>Microphone: {isRecording ? 'on' : 'off'}</p>
                        <button className="btn btn-light btn-block btn-block-record" id="start-record-btn" style={{minHeight: '330px'}}
                              onClick={isRecording ? stopSpeechToText : startSpeechToText}>
      
                          <h6 style={{color: '#fff'}}>TAP HERE TO RECORD Dictation...</h6>
                          <div className="box">
                            <div className="circle_ripple" />
                            <div className="circle_ripple-2" />
                            <div className="circles">
                              <div className="circles-2">
                                <i className="fa fa-microphone" />
                              </div>
                            </div>
                          </div>
                        </button>
                        <hr className="m-2" />
                        <button className="btn btn-light btn-block" id="stopDictation"   onClick={
                          
                      
                       
                      
                          isRecording ? stopSpeechToText : ''
                        
                          }
                          
                          >Stop Recording</button>
                        <button className="btn btn-light btn-block" id="pause-record-btn" onClick={()=>
                          { 
                          setSpeech(' ')}
                        }>Reset Recording</button>
                        <button className="btn btn-light my-2" id="save-note-btn">Save Dictation</button>
                        <button className="btn btn-light pull-right my-2" id="clearDectation">Clear</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="overlay toggle-menu" />
              
            </div>
            {/* End container-fluid*/}
          </div>
          {/* <a href="javaScript:void();" class="back-to-top"><i class="fa fa-angle-double-up"></i> </a> */}
        
          <footer className="footer">
            <div className="container">
              <div className="text-center">
                Copyright © 2022 Voice Vet
              </div>
            </div>
          </footer>
         
          <div className="right-sidebar">
            <div className="switcher-icon">
              <i className="zmdi zmdi-settings zmdi-hc-spin" />
            </div>
            <div className="right-sidebar-content">
              <p className="mb-0">Gaussion Texture</p>
              <hr />
              <ul className="switcher">
                <li id="theme1" />
                <li id="theme2" />
                <li id="theme3" />
                <li id="theme4" />
                <li id="theme5" />
                <li id="theme6" />
              </ul>
              <p className="mb-0">Gradient Background</p>
              <hr />
              <ul className="switcher">
                <li id="theme7" />
                <li id="theme8" />
                <li id="theme9" />
                <li id="theme10" />
                <li id="theme11" />
                <li id="theme12" />
                <li id="theme13" />
                <li id="theme14" />
                <li id="theme15" />
              </ul>
            </div>
          </div>
          
        </div>

        
        {/* Button trigger modal */}
        {/* Modal */}
        <div className="modal fade" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">Modal Dictations</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <ul id="notes">
                  <li>
                    <p className="no-notes">You don't have any notes.</p>
                  </li>
                </ul>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-light" data-dismiss="modal">Close</button>
                {/* <button type="button" class="btn btn-light">Save changes</button> */}
              </div>
            </div>
          </div>
        </div>
      </div></>
  );
}
export default VoiceRecord;
