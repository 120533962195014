//import logo from './logo.svg';
import '../styles/css/Home.css'
import logo from '../styles/images/logo-icon.png'
import avatar from '../styles/images/profile.png'
function Home() {
  return (
    <div className='dashboard'>
      {/* start loader */}
      <div id="pageloader-overlay" className="visible incoming"><div className="loader-wrapper-outer"><div className="loader-wrapper-inner"><div className="loader" /></div></div></div>
      {/* end loader */}
      {/* Start wrapper*/}
      <div id="wrapper">
        <header className="topbar-nav">
          <nav className="navbar navbar-expand fixed-top">
            <ul className="navbar-nav mr-auto align-items-center">
              <li className="nav-item">
                <a href="/home">
                  <img src={logo} className="logo-icon" alt="logo icon" />
                  <h5 className="logo-text">Voice vet</h5>
                </a>
              </li>
              <li className="nav-item">
                
              </li>
            </ul>
            <ul className="navbar-nav align-items-center right-nav-link">
             
              <li className="nav-item">
                <a className="nav-link dropdown-toggle dropdown-toggle-nocaret" data-toggle="dropdown" href="#">
                  <span className="user-profile"><img src={avatar} className="img-circle" alt="user avatar" /></span>
                </a>
                <ul className="dropdown-menu dropdown-menu-right">
                  <li className="dropdown-item user-details">
                    <a href="javaScript:void();">
                      <div className="media">
                        <div className="avatar"><img className="align-self-start mr-3" src={avatar} alt="user avatar" /></div>
                        <div className="media-body">
                          <h6 className="mt-2 user-title">Sarajhon Mccoy</h6>
                          <p className="user-subtitle">mccoy@example.com</p>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="dropdown-divider" />
                  <li className="dropdown-item"><i className="icon-envelope mr-2" /> Inbox</li>
                  <li className="dropdown-divider" />
                  <li className="dropdown-item"><i className="icon-wallet mr-2" /> Account</li>
                  <li className="dropdown-divider" />
                  <li className="dropdown-item"><i className="icon-settings mr-2" /> Setting</li>
                  <li className="dropdown-divider" />
                  <li className="dropdown-item"><a href="login.html"><i className="icon-power mr-2" /> Logout</a></li>
                </ul>
              </li>
            </ul>
          </nav>
        </header>
        <div className="loader-wrapper"><div className="lds-ring"><div /><div /><div /><div /></div></div>
        <div className="m-3">
          <div className="text-center ">
            <img src={logo} alt="logo icon" />
          </div>
          <div className="card-title text-uppercase text-center py-3">Welcome to voice vet</div>
        </div>
        <div className="container-fluid" style={{width: '75%'}}>
          <div className="row">
            <div className="col-lg-4 mb-1 mt-1">
              <a href="/schedular">
                <div className="container card">
                  <div className="content">
                    <span className="pull-right"><i className="fa fa-calendar" /></span>
                    <div className="clearfix" />
                    <h1 className="mt-3">Schedular</h1>
                    {/* <p>This is an amazing card with nice animation</p> */}
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-4 mb-1 mt-1">
              <a href="/patients">
                <div className="container card">
                  <div className="content">
                    <span className="pull-right"><i className="fa fa-users" /></span>
                    <div className="clearfix" />
                    <h1 className="mt-3">Patients</h1>
                    {/* <p>This is an amazing card with nice animation</p> */}
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-4 mb-1 mt-1">
              <a href="/reports">
                <div className="container card">
                  <div className="content">
                    <span className="pull-right"><i className="fa fa-files-o" /></span>
                    <div className="clearfix" />
                    <h1 className="mt-3">Reports</h1>
                    {/* <p>This is an amazing card with nice animation</p> */}
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/*wrapper*/}
    </div>
  );
}
export default Home;
