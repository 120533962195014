import React from "react"
import { Link } from 'react-router-dom';
import Logo from "../snippets/Logo";

export default (props) => {        
  return (
    <div>
    {/*Start sidebar-wrapper*/}
    <div id="sidebar-wrapper" data-simplebar data-simplebar-auto-hide="true">
        <div className="brand-logo">
        {/* <a href="index.html">
            <img src="assets/images/logo-icon.png" className="logo-icon" alt="logo icon" />
            <h5 className="logo-text">Voice vet</h5>
        </a> */}
        <Logo/>
        </div>
        <ul className="sidebar-menu do-nicescrol">
        <li className="sidebar-header">MAIN NAVIGATION</li>
        <li>
            <a href="/home">
            <i className="zmdi zmdi-home" /> <span>Home</span>
            </a>
        </li>
        <li>
            <a href="/schedular">
            <i className="zmdi zmdi-calendar-check" /> <span>Schedular</span>
            </a>
        </li>
        {/* <li>
    <a href="voiceRecord.html">
    <i class="zmdi zmdi-plus"></i> <span>Create Dictation</span>
    </a>
    </li> */}
        <li>
            <a href="/patients">
            <i className="zmdi zmdi-account" /> <span>Patients</span>
            </a>
        </li>
        <li>
            <a href="/reports">
            <i className="zmdi zmdi-file" /> <span>Reports</span>
            </a>
        </li>
        <li>
            <a href="/profile">
            <i className="zmdi zmdi-face" /> <span>Profile</span>
            </a>
        </li>
        <li>
            <a href="/login">
            <i className="fa fa-sign-out" aria-hidden="true" /><span>Logout</span>
            </a>
        </li>
        </ul>
    </div>
    {/*End sidebar-wrapper*/}
    </div>
  )
}