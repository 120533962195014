//import logo from './logo.svg';

import { useSelector } from "react-redux";
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { OtpVerify } from "./VerifyOtpSlice";
import './verify.css';
import { useHistory } from "react-router-dom";




function VerifyOtp() {

  const dispatch = useDispatch();
  const history = useHistory();

  // const AddNewCountryDialog = (prop) => {
  // const { openAddCountryDialog, setOpenAddCountryDialog } = prop;
  // const [icon,setIcon] = useState('')
  const validationSchema = Yup.object().shape({
    verify_otp1: Yup.string().required('otp is required'),
    verify_otp2: Yup.string().required('otp is required'),
    verify_otp3: Yup.string().required('otp is required'),
    verify_otp4: Yup.string().required('otp is required'),
    verify_otp5: Yup.string().required('otp is required'),
    verify_otp6: Yup.string().required('otp is required'),
    
  });



  const defaultValues = {
    verify_otp1: '',
    verify_otp2: '',
    verify_otp3 :'',
    verify_otp4: '',
    verify_otp5: '',
    verify_otp6:''


  };

  const { handleSubmit, watch, control, formState, register } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const { isValid, dirtyFields, errors } = formState;


  const loginData = useSelector((state)=>state.login);
  console.log(loginData)



  // const dispatch = useDispatch();


  const data = watch();
  console.log(data)
  const onSubmit = (model) => {

console.log(data)

console.log(data.verify_otp3)
data.verify_otp = data.verify_otp1.concat(data.verify_otp2,data.verify_otp3,data.verify_otp4,data.verify_otp5,data.verify_otp6)
console.log(data.verify_otp)

// {vf:data.verify_otp}

    // const countryModel = JSON.stringify(model)
    // dispatch(addCountry(countryModel));


    // const foo = useSelector((state)=>state.login);
    // console.log(foo)
 

// const handleSubmit = (e) => {
  // e.preventDefault()
  const obj = {
    otp :data.verify_otp,
    accountId: loginData!=null?loginData.accountId:"",
    userId:loginData!=null?loginData.userID:"",
 }

  const object = JSON.stringify(obj);
  console.log(object)
  dispatch(OtpVerify(object))
  history.push('/home')
// }

  }
  return (
    <div>
      {/* start loader */}
      <div id="pageloader-overlay" className="visible incoming"><div className="loader-wrapper-outer"><div className="loader-wrapper-inner"><div className="loader" /></div></div></div>
      {/* end loader */}

      {/* Start wrapper*/}
      <div id="wrapper">
        <div className="loader-wrapper"><div className="lds-ring"><div /><div /><div /><div /></div></div>
        <div className="card card-authentication1 mx-auto my-5">
          <div className="card-body">
            <div className="card-content p-2">
              <div className="text-center">
                <img src="assets/images/logo-icon.png" alt="logo icon" />
              </div>
              <div className="card-title text-uppercase text-center py-3">Sign In</div>
              <div className="card-title text-uppercase text-center py-0">Verify OTP</div>
              <form onSubmit={handleSubmit(onSubmit)}>






                <label>Please enter OTP</label>
                <div className="form-group"  id="emailInput">
                  <div>

                    <Controller
                      name="verify_otp1"

                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <div className="form-row">
                          {/* <input id  {...register("verify_otp")} maxLength={4} className="text-center form-control form-control-sm input-shadow" placeholder={0}  /> */}
                          {/* {errors.verify_otp && <span>This field is required</span>} */}
                          <div className="col-2">


                            <input id  {...register("verify_otp1")} maxLength={1}
                              className="text-center form-control form-control-sm input-shadow" placeholder={0} />
                          </div>
                          <div className="col-2">
                            <input id  {...register("verify_otp2")} maxLength={1} className="text-center form-control form-control-sm input-shadow" placeholder={0} />
                          </div>
                          <div className="col-2">
                            <input id  {...register("verify_otp3")} maxLength={1} className="text-center form-control form-control-sm input-shadow" placeholder={0} />
                          </div>
                          <div className="col-2">
                            <input id {...register("verify_otp4")} maxLength={1} className="text-center  form-control form-control-sm input-shadow" placeholder={0} />
                          </div>
                          <div className="col-2">
                            <input id {...register("verify_otp5")} maxLength={1} className="text-center  form-control form-control-sm input-shadow" placeholder={0} />
                          </div>
                          <div className="col-2">
                            <input id {...register("verify_otp6")} maxLength={1} className="text-center  form-control form-control-sm input-shadow" placeholder={0} />
                          </div>
                          
                          
                          
                        
                        </div>

                        
                      )}
                    />
                    

                  </div>
                </div>
                <div style={{
                  paddingLeft: 95
                }}>
                           
                           {(errors.verify_otp1 || errors.verify_otp2 || errors.verify_otp3 || errors.verify_otp4 || errors.verify_otp5 || errors.verify_otp6)  && <span style={{justifyContent:'center',color:'red'}}>This field is required</span>}
                         </div>
                         <br></br>
                <div className="form-group" style={{ display: 'none' }} id="phoneInput">
                  <label htmlFor="exampleInputUsername" className="sr-only">Phone</label>
                  <div className="position-relative has-icon-right">
                    <input type="text" id="exampleInputUsername" className="form-control input-shadow" placeholder="Enter Phone" />
                    <div className="form-control-position">
                      <i className="icon-phone" />
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn btn-light btn-block">Verify OTP</button>


              </form>
            </div>
          </div>
          {/* <div className="card-footer text-center py-3">
            <p className="text-warning mb-0"> <a href="#" style={{ color: '#000' }}> Re-Send OTP</a> in <span id="countdowntimer">30</span>Sec</p>
          </div> */}
        </div>



        {/*Start Back To Top Button*/}
        <a href="javaScript:void();" className="back-to-top"><i className="fa fa-angle-double-up" /> </a>
        {/*End Back To Top Button*/}
        {/*start color switcher*/}
        <div className="right-sidebar">
          <div className="switcher-icon">
            <i className="zmdi zmdi-settings zmdi-hc-spin" />
          </div>
          <div className="right-sidebar-content">
            <p className="mb-0">Gaussion Texture</p>
            <hr />
            <ul className="switcher">
              <li id="theme1" />
              <li id="theme2" />
              <li id="theme3" />
              <li id="theme4" />
              <li id="theme5" />
              <li id="theme6" />
            </ul>
            <p className="mb-0">Gradient Background</p>
            <hr />
            <ul className="switcher">
              <li id="theme7" />
              <li id="theme8" />
              <li id="theme9" />
              <li id="theme10" />
              <li id="theme11" />
              <li id="theme12" />
              <li id="theme13" />
              <li id="theme14" />
              <li id="theme15" />
            </ul>
          </div>
        </div>
        {/*end color switcher*/}
      </div>{/*wrapper*/}



    </div>

  );
}


export default VerifyOtp;
