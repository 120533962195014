import React from "react"
import { Link } from 'react-router-dom';
import logo from '../styles/images/logo-icon.png'
export default (props) => {
  return (
    <React.Fragment>
      {/* Logo */}
      <h1 className="header__logo">
        <Link to="/home">
          
            <img alt="Image" data-sizes="auto" data-srcset={logo} src={logo} className="logo-icon" />
            <h5 className="logo-text">Voice vet</h5>
        </Link>
      </h1>
      {/* End logo */}
    </React.Fragment>
  )
}