import { publicRequest } from "../../requestmethods";

const { createAsyncThunk, createSlice } = require("@reduxjs/toolkit");
//const { publicRequest } = require("../../requestMethods");


// export const getCourseTypeList = createAsyncThunk(
//   "courseApp/course/getCourseTypeList",
//   async () => {
//     const response = await publicRequest.get("coursetype/");
//     console.log(response)
//     return response.data;
//   }
// );

// export const GetAllPatients = createAsyncThunk(
//   "Patient",
//   async (PatientModel, { dispatch }) => {
//     console.log(PatientModel)
//     console.log("Add Patient");
//     const response = await publicRequest.post("Patient/GetAllPatients",PatientModel);
//     console.log(response);
//    // dispatch(getCourseList())
//     return response;
//   }
// );


export const GetPatientsByAppointmentDate = createAsyncThunk(
    "Patient",
    async (PatientModel, { dispatch }) => {
      console.log(PatientModel)
      console.log("Add Patient");
      const response = await publicRequest.post("Patient/GetPatientsByAppointmentDate",PatientModel);
      console.log(response);
     // dispatch(getCourseList())
      return response;
    }
  );

// export const updateCourse = createAsyncThunk(
//   "courseTypeApp/courseType/updateCourse",
//   async (courseModel, { dispatch }) => {
//     // console.log("Add Course");
//     const response = await publicRequest.put("coursetype/", courseModel);
//     console.log(response);
//     dispatch(getCourseList())
//     return response;
//   }
// );


const GetPatientsByAppointmentDateSlice = createSlice({
  name: "Patient",
  initialState: null,    
  reducers: {},
  extraReducers: {
 //   [getCourseTypeList.fulfilled]: (state, action) => action.payload,
    [ GetPatientsByAppointmentDate.fulfilled]: (state, action) => action.payload,
    // [updateCourse.fulfilled]: (state, action) => action.payload,
    //   [updateCountry.fulfilled] : (state,action) => action.payload,
  },
});


export default GetPatientsByAppointmentDateSlice.reducer;