import React, { useEffect } from "react"
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom"

import Home from '../templates/Home';
import { useShopify } from "../hooks"
import { NotificationContainer } from 'react-notifications';
import Schedular from "../templates/Schedular/Schedular";
import Patient from "../templates/Patients/Patients";
import Login from "../templates/Login/Login";
import Profile from "../templates/Profile";
import Reports from "../templates/Reports";
import VerifyOtp from "../templates/verifyotp/VerifyOtp";
import  VoiceRecord from "../templates/VoiceRecord";

export default (props) => {
  // const {
  //   createShop,
   
  // } = useShopify()

  // useEffect(() => {
  // }, [])

	return (
   
		<Router>
      <div id="app">
        <Switch>
  				<Route exact path="/" render={() => <Redirect to="/login" />} />
  				<Route path="/home" component={Home} />
  				<Route path="/schedular" component={Schedular} />
  				<Route path="/patients" component={Patient} />
  				<Route path="/reports" component={Reports} />
  				<Route path="/profile" component={Profile} />
  				<Route path="/login" component={Login} />
  				<Route path="/verify" component={VerifyOtp} />
          <Route path="/VoiceRecord" component={VoiceRecord} />
          
        </Switch>
      </div>
      <NotificationContainer/>
		</Router>
    
	)
}
