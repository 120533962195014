//import logo from './logo.svg';

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Sidebar from "../../sections/Sidebar";
import TopNav from "../../sections/TopNav";
import { GetPatientsByAppointmentDateRange } from "./GetByDateRangePatientsSlice";
import { GetPatientsByAppointmentDate } from "./GetPatientsByAppointmentDateSlice";
import { GetPatientByNameSchedular } from "./GetPatientsByNameSlice";





function Schedular() {

  const dispatch = useDispatch();

  useEffect(()=>{


    const object={
      "FromDate": "2022-01-05",
  "ToDate": "2022-01-19",
  "userId": "0",
  "providerNumber": "0"
    }
    console.log(object)
    dispatch(GetPatientsByAppointmentDateRange(JSON.stringify(object)))


    

    const obj ={
      "name": "Adams",
  "userId": "0",
  "providerNumber": "0",
  "pmsTypeId": "1",
  "accountId": "2"


    }

   


  console.log(obj)
  dispatch(GetPatientByNameSchedular(JSON.stringify(obj)))


  const obj1 ={
    
      "appointmentDate": "2021-01-20",
      "userId": "0",
      "providerNumber": "0"
        }

        console.log(obj1)
        dispatch(GetPatientsByAppointmentDate(JSON.stringify(obj1)))



})
const schedule = [
  {
    "patNum": 5,
    "lName": "Scarlet",
    "fName": "Brown",
    "middleI": "",
    "preferred": null,
    "patStatus": null,
    "gender": "Female",
    "birthdate": "1990-03-02T00:00:00",
    "email": null,
    "position": null,
    "priProv": null,
    "secProv": 0,
    "age": 0,
    "appointmentId": 5,
    "salutation": null,
    "isExisting": 0,
    "aptTime": "10:45 AM",
    "aptStatus": null
},
{
    "patNum": 28,
    "lName": "Adams",
    "fName": "Ashley",
    "middleI": "",
    "preferred": null,
    "patStatus": null,
    "gender": "Female",
    "birthdate": "1991-09-09T00:00:00",
    "email": null,
    "position": null,
    "priProv": null,
    "secProv": 0,
    "age": 0,
    "appointmentId": 3,
    "salutation": null,
    "isExisting": 0,
    "aptTime": "03:46 AM",
    "aptStatus": null
}
]

// const handelSearchByDate = (e) => {
//     console.log(e.target.value)
//     dispatch(GetPatientsByAppointmentDate(JSON.stringify()))
    

// }
  return (
    <div>
      {/* start loader */}
      <div id="pageloader-overlay" className="visible incoming"><div className="loader-wrapper-outer"><div className="loader-wrapper-inner"><div className="loader" /></div></div></div>
      {/* end loader */}
      {/* Start wrapper*/}
      <div id="wrapper">
        <Sidebar/>
        <TopNav/>
        <div className="clearfix" />
        <div className="content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title mb-1">List of Appointments</h5>
                    <hr className="my-2" />
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label className="mb-0">From Date</label>
                          <input type="date" className="form-control input-shadow" onChange={(e)=>handelSearchByDate(e)} />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label className="mb-0">To Date</label>
                          <input type="date" className="form-control input-shadow" onChange={(e)=>handelSearchByDate(e)} />
                        </div>
                      </div>
                      {/* <div className="col-lg-3">
                        <div className="form-group">
                          <label className="mb-0">Search with Time</label>
                          <input type="time" className="form-control input-shadow" />
                        </div>
                      </div> */}
                      <div className="col-lg-3" />
                      <div className="col-lg-3">
                        <label className="mb-0">Search with Pet Owner Name</label>
                        <form className="search-bar m-0">
                          <input type="text" className="form-control" placeholder="Search" style={{width: '100%'}} id="dataInput" onkeyup="tableDataFunction()" />
                          <a href="javascript:void();"><i className="icon-magnifier" /></a>
                        </form>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-hover" id="tableData">
                        <thead>
                          <tr>
                            <th scope="col">Patient Name</th>
                            <th scope="col">Pet Owner Name</th>
                            <th scope="col">Appointment Date</th>
                            <th scope="col">Appointment Time</th>
                            <th scope="col">Status</th>
                            <th scope="col" className="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                            schedule.map((s)=>{
                              return(
                                  <tr>
                                   <th scope="row">Cat</th>
                                   <td>{s.fName}</td>
                                   <td>01/05/2022</td>
                                   <td>{s.aptTime}</td>
                                   <td>{s.aptStatus}</td>
                                   <th>
                              {/* <button class="btn btn-light btn-sm px-3">Edit</button> */}
                              <a href="/VoiceRecord"><button className="btn btn-light btn-sm px-3">Select</button></a>
                            </th>
                                  </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>{/*End Row*/}
            {/*start overlay*/}
            <div className="overlay toggle-menu" />
            {/*end overlay*/}
          </div>
          {/* End container-fluid*/}
        </div>{/*End content-wrapper*/}
        {/*Start Back To Top Button*/}
        <a href="javaScript:void();" className="back-to-top"><i className="fa fa-angle-double-up" /> </a>
        {/*End Back To Top Button*/}
        {/*Start footer*/}
        <footer className="footer">
          <div className="container">
            <div className="text-center">
              Copyright © 2022 Voice Vet
            </div>
          </div>
        </footer>
        {/*End footer*/}
        {/*start color switcher*/}
        <div className="right-sidebar">
          <div className="switcher-icon">
            <i className="zmdi zmdi-settings zmdi-hc-spin" />
          </div>
          <div className="right-sidebar-content">
            <p className="mb-0">Gaussion Texture</p>
            <hr />
            <ul className="switcher">
              <li id="theme1" />
              <li id="theme2" />
              <li id="theme3" />
              <li id="theme4" />
              <li id="theme5" />
              <li id="theme6" />
            </ul>
            <p className="mb-0">Gradient Background</p>
            <hr />
            <ul className="switcher">
              <li id="theme7" />
              <li id="theme8" />
              <li id="theme9" />
              <li id="theme10" />
              <li id="theme11" />
              <li id="theme12" />
              <li id="theme13" />
              <li id="theme14" />
              <li id="theme15" />
            </ul>
          </div>
        </div>
        {/*end color switcher*/}
      </div>{/*End wrapper*/}
    </div>
  );
}
export default Schedular;
