import { publicRequest } from "../../requestmethods";

const { createAsyncThunk, createSlice } = require("@reduxjs/toolkit");
// const { publicRequest } = require("../../requestMethods");


// export const getCourseTypeList = createAsyncThunk(
//   "courseApp/course/getCourseTypeList",
//   async () => {
//     const response = await publicRequest.get("coursetype/");
//     console.log(response)
//     return response.data;
//   }
// );

export const OtpVerify = createAsyncThunk(
  "VerifyOtp",
  async (verifyotpModel, { dispatch }) => {
    console.log(verifyotpModel)

    const response = await publicRequest.post("Login/ValidateOTP", verifyotpModel);
    console.log(response);
    // dispatch(getCourseList())
    return response;
  }
);

// export const updateCourse = createAsyncThunk(
//   "courseTypeApp/courseType/updateCourse",
//   async (courseModel, { dispatch }) => {
//     // console.log("Add Course");
//     const response = await publicRequest.put("coursetype/", courseModel);
//     console.log(response);
//     dispatch(getCourseList())
//     return response;
//   }
// );


const VerifyOtpSlice = createSlice({
  name: "VerifyOtp",
  initialState: null,
  reducers: {},
  extraReducers: {
    //   [getCourseTypeList.fulfilled]: (state, action) => action.payload,
    [OtpVerify.fulfilled]: (state, action) => action.payload,
    // [updateCourse.fulfilled]: (state, action) => action.payload,
    //   [updateCountry.fulfilled] : (state,action) => action.payload,
  },
});


export default VerifyOtpSlice.reducer;