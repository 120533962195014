//import logo from './logo.svg';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../sections/Sidebar";
import TopNav from "../sections/TopNav";
import { GetPatientByPatNum } from "./Patients/GetPatientByPatNumSlice";
import { GetPatientByName } from "./Patients/GetPatientsByNameSlice";
import { GetAllPatients } from "./Patients/PatientsSlice";

function Reports() {
  
  const dispatch = useDispatch();

  const [pat,setPat] = useState();

  useEffect(()=>{

    console.log("hello")
        const object1=
        {
          "userId": "0",
          "providerNumber": "1",
          "pmsTypeId": "1",
          "accountId": "4"
        }
        // const object = JSON.stringify(data);
       // console.log(object1)
        dispatch(GetAllPatients(JSON.stringify(object1)))


        const obj ={
          "name": "Adams",
          "userId": "0",
         "providerNumber": "0",
         "pmsTypeId": "1",
         "accountId": "2"
    
    
        }
       //console.log(obj)
      dispatch(GetPatientByName(JSON.stringify(obj)))

      // getPatientByNum

      const object2 ={
        "PatNo": "5",
        "userId": "0",
        "providerNumber": "0",
        "PMSTypeId": "1"
      }
      
      dispatch(GetPatientByPatNum(JSON.stringify(object2)))

  },[])


  // const AllPatients = useSelector((state)=>state.Patients);
  // console.log(AllPatients);

  // const GetByNamePatients = useSelector((state)=>state.GetPatientsByName);
  // console.log(AllPatients);



  // useEffect(()=>{
  //     if(AllPatients != undefined){
  //       setPat(AllPatients)
  //     }
  //     else if( GetByNamePatients != undefined)
  // },[AllPatients])


  // const AllPatients = useSelector((state)=>state.Patients);
  // console.log(AllPatients);

  const handleSearchByName = (e) => {
    console.log(e.target.value)
    dispatch(GetPatientByName(JSON.stringify()))
    

}



  const patients = [
    {
      "patNum": 31,
      "lName": "Stewart",
      "fName": "Albert",
      "middleI": "",
      "preferred": "",
      "patStatus": "0",
      "gender": "Male",
      "birthdate": "1991-09-09T00:00:00",
      "email": "",
      "position": null,
      "priProv": "1",
      "secProv": 0,
      "age": 0,
      "appointmentId": 0,
      "salutation": null,
      "isExisting": 0,
      "aptTime": null,
      "aptStatus": null
  },
  {
      "patNum": 28,
      "lName": "Adams",
      "fName": "Ashley",
      "middleI": "",
      "preferred": "",
      "patStatus": "0",
      "gender": "Female",
      "birthdate": "1991-09-09T00:00:00",
      "email": "",
      "position": null,
      "priProv": "1",
      "secProv": 0,
      "age": 0,
      "appointmentId": 0,
      "salutation": null,
      "isExisting": 0,
      "aptTime": null,
      "aptStatus": null
  },
  {
      "patNum": 5,
      "lName": "Scarlet",
      "fName": "Brown",
      "middleI": "",
      "preferred": "",
      "patStatus": "0",
      "gender": "Female",
      "birthdate": "1990-03-02T00:00:00",
      "email": "",
      "position": null,
      "priProv": "1",
      "secProv": 0,
      "age": 0,
      "appointmentId": 0,
      "salutation": null,
      "isExisting": 0,
      "aptTime": null,
      "aptStatus": null
  },
  ]


  return (
    <div>
      {/* start loader */}
      <div id="pageloader-overlay" className="visible incoming"><div className="loader-wrapper-outer"><div className="loader-wrapper-inner"><div className="loader" /></div></div></div>
      {/* end loader */}
      {/* Start wrapper*/}
      <div id="wrapper">
      <Sidebar/>
      <TopNav/> 
        <div className="clearfix" />
        
        <div className="content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title mb-1">List of Patients</h5>
                  <hr className="my-2" />
                  <div className="row">
                    <div className="col-lg-9"> </div>
                    <div className="col-lg-3">
                      <div className="form-group">
                        <label className="mb-0">Search with Pet Owner Name</label>
                        <form className="search-bar m-0">
                          <input type="text" className="form-control" onChange={(e)=> handleSearchByName(e)} placeholder="Search" style={{width: '100%'}} id="dataInput" onkeyup="tableDataFunction()" />
                          <a href="javascript:void();"><i className="icon-magnifier" /></a>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-hover" id="tableData">
                      <thead>
                        <tr>
                          <th scope="col">Patient Name</th>
                          <th scope="col">Pet Owner Name</th>
                          <th scope="col" className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          patients.map((p)=>{
                            return(
                              <tr>
                                <th scope="row">Cat</th>
                                <td>{p.fName}</td>
                                <th className="text-center">
                            {/* <button class="btn btn-light btn-sm px-3">Edit</button> */}
                            <a href="#">
                              <button className="btn btn-light btn-sm px-3">Get Report</button>
                            </a>
                          </th>
                              </tr>
                            )
                          })
                        }

                        
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>{/*End Row*/}
          {/*start overlay*/}
          <div className="overlay toggle-menu" />
          {/*end overlay*/}
        </div>
        {/* End container-fluid*/}
      </div>{/*End content-wrapper*/}



        {/*Start Back To Top Button*/}
        <a href="javaScript:void();" className="back-to-top"><i className="fa fa-angle-double-up" /> </a>
        {/*End Back To Top Button*/}
        {/*Start footer*/}
        <footer className="footer">
          <div className="container">
            <div className="text-center">
              Copyright © 2022 Voice Vet
            </div>
          </div>
        </footer>
        {/*End footer*/}
        {/*start color switcher*/}
        <div className="right-sidebar">
          <div className="switcher-icon">
            <i className="zmdi zmdi-settings zmdi-hc-spin" />
          </div>
          <div className="right-sidebar-content">
            <p className="mb-0">Gaussion Texture</p>
            <hr />
            <ul className="switcher">
              <li id="theme1" />
              <li id="theme2" />
              <li id="theme3" />
              <li id="theme4" />
              <li id="theme5" />
              <li id="theme6" />
            </ul>
            <p className="mb-0">Gradient Background</p>
            <hr />
            <ul className="switcher">
              <li id="theme7" />
              <li id="theme8" />
              <li id="theme9" />
              <li id="theme10" />
              <li id="theme11" />
              <li id="theme12" />
              <li id="theme13" />
              <li id="theme14" />
              <li id="theme15" />
            </ul>
          </div>
        </div>
        {/*end color switcher*/}
      </div>{/*End wrapper*/}
    </div>
  );
}
export default Reports;
