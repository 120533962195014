const { createAsyncThunk, createSlice } = require("@reduxjs/toolkit");
//const { publicRequest } = require("../../requestMethods");


// export const getCourseTypeList = createAsyncThunk(
//   "courseApp/course/getCourseTypeList",
//   async () => {
//     const response = await publicRequest.get("coursetype/");
//     console.log(response)
//     return response.data;
//   }
// );

// export const addCourse = createAsyncThunk(
//   "courseTypeApp/courseType/addCourse",
//   async (courseModel, { dispatch }) => {
//     console.log("Add Course");
//     const response = await publicRequest.post("coursetype/", courseModel);
//     console.log(response);
//     dispatch(getCourseList())
//     return response;
//   }
// );

// export const updateCourse = createAsyncThunk(
//   "courseTypeApp/courseType/updateCourse",
//   async (courseModel, { dispatch }) => {
//     // console.log("Add Course");
//     const response = await publicRequest.put("coursetype/", courseModel);
//     console.log(response);
//     dispatch(getCourseList())
//     return response;
//   }
// );


const  FooterSlice = createSlice({
  name: "Footer",
  initialState: [{
        id:1,username:"hepin"
  }],
  reducers: {},
  extraReducers: {
 //   [getCourseTypeList.fulfilled]: (state, action) => action.payload,
    // [addCourse.fulfilled]: (state, action) => action.payload,
    // [updateCourse.fulfilled]: (state, action) => action.payload,
    //   [updateCountry.fulfilled] : (state,action) => action.payload,
  },
});


export default FooterSlice.reducer;